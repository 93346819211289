import React from 'react'
import  Navbar  from "../inc/Navbar";
import Develop from "../pages/Develop";
import {Link} from "react-router-dom";
import Reviews from './Reviews';

function Ourdevelop() {
  return (
    <div>
        <Navbar/>
        <br/>
        <br/>
        <br/>
        <br/>
       
        <div className='websitedevelopkrishnagar'>
        <div className="album py-2">

<div className="container mt-4">
        <nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li className="breadcrumb-item"><Link to="/" href="#">Home</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Our Development Page</li>
  </ol>
</nav>
</div>
</div>
</div>
      
        <br/>
    
        <Develop/>
        <br/>
        <div className="album py-0">
                <div className="container">
             <h1 style={{color:"grey"}}> REVIEWS</h1>
                    </div>
                    </div>
        <Reviews/>
        <br/>

    </div>
  )
}

export default Ourdevelop